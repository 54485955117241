import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import marked from "marked"

//faq accordion
// import { Accordion, Card } from "react-bootstrap"

const AboutUs = () => (
  <Layout>
    <SEO title="About Us" description="About JBL Sourcing" />
    <div
      className="page-headline-about"

    >
      <div className="container">
        <div className="section-heading text-center">
          <h6 className="font-weight-bold text-uppercase text-white flair">
            about us
          </h6>
          <h1 className="text-white">Who We Are</h1>
        </div>
      </div>
    </div>
    <div className="page-content">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            {/* <div className="mb-5">
              <h2 className="text-center p-0 mt-3 mb-2">
                Integrated firm within Med-Tech, Biopharmaceuticals and Life
                Science
              </h2>
              <div className="heading-line2 mx-auto" />
            </div> */}
            <div className="heading-line2 mx-auto" />
            <p className="mt-3">
              JBL Sourcing is a provider of full-service recruitment, retention,
              and other consultative solutions. Our niche area integrates us at
              the highest levels of leadership. Our clients enjoy a full array
              of solutions customized to meet their unique and individual needs,
              including Retained and Contingency Search, Interim Staffing, and
              Succession Planning. We listen carefully to our clients’ corporate
              desired outcome. We identify the best course of action and work
              with diligence to provide the solution. We measure our success by
              solving client problems and putting the best person in the right
              position. We build strong relationships with our clients that get
              to the heart of achieving their objectives and creating pathways
              to success. Our recruiting systems enable us to evaluate and
              precision-match candidates with specific client needs. We do this
              while maintaining low interview to placement ratios.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default AboutUs
